<template>
  <div class="addon-buy">
    <div class="flex flex-wrap">
      <div v-for="addon in addons" :key="addon._id" @click.prevent="show(addon, addon.addon_id)" class="w-full lg:w-1/3">
        <div class="text-center addon-box mx-2 my-2 rounded shadow hover:shadow-md transition duration-200" v-bind:style="{ 'background-image': 'linear-gradient(rgb(26 141 233 / 80%), rgb(26 141 233 / 21%)) , url(' + addon.image + ')' }">
          <div>
            <h1 class="addon-text text-xl font-bold mb-3 text-white">
              {{ addon.name }}
            </h1>
            <span v-if="(user.role == 'user' || user.role == 'superuser' || id) && addon.active == true" class="addon-active">{{ $t("status_active") }}</span>
            <span v-if="(user.role == 'user' || user.role == 'superuser' || id) && addon.active == false" class="addon-inactive">{{ $t("status_inactive") }}</span>
          </div>
        </div>
      </div>
    </div>

    <modal class="modal-inner" v-for="addon in addons" :key="addon._id" :name="addon.addon_id" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="500" height="auto" :scrollable="true">
      <span class="close-button" @click="hide(addon.addon_id)"><BaseIcon icon="times-circle" class="text-white"/></span>
      <div class="modal-header text-lg bg-primary-50 font-serif">
        {{ addon.name }}
      </div>
      <div class="modal-body">
        <div class="flex flex-wrap items-center">
          <div class="w-full lg:w-1/2">
            <img class="addon-image" :src="addon.image" />
          </div>
          <div class="w-full lg:w-1/2 px-4">
            <pre class="text-sm whitespace-normal font-sans">{{ addon.description }}</pre>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="footer-container clearfix">
          <a :href="getContactMailLink(addon.name)" class="btn-blue text-sm px-4 py-3 float-right">{{ $t("contact") }}<BaseIcon icon="paper-plane" class="ml-1"/></a>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
const MODAL_WIDTH = 800;

export default {
  name: "AddonBuy",
  title() {
    return this.$t("page_titles.additional_services");
  },
  data() {
    return {
      type: this.$route.params.type,
      id: this.$route.params.id,
      customers: [],
      units: [],
      addons: [],
      seller_email: "support@securdia.se",
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    userAddons() {
      return this.$store.state.addons;
    },
  },

  methods: {
    async getAddons() {
      let type = this.type;

      if (this.user && this.user.role == "user") type = "unit";
      else if (this.user && this.user.role == "superuser") type = "customer";
      else if (!this.id) return this.getAddonsList();

      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/addons/all/${type}/${this.id}`)
        .then((response) => {
          // to show active addon first, then inactive addon
          let activeAddons = [],
            inactiveAddons = [];

          if (type == "customer") this.customers = response.data.customers;
          else if (type == "unit") this.units = response.data.units;

          response.data.addons.forEach((addon) => {
            // to indicate on ui
            addon.active = false;

            // if no id passed
            if (this.user.role == "superuser") {
              let customerAddons = _.flatten(_.map(this.customers, "addons"));

              if (customerAddons.indexOf(addon.addon_id) > -1) addon.active = true;
            } else if (this.user.role == "user") {
              let unitAddons = _.flatten(_.map(this.units, "addons"));

              if (unitAddons.indexOf(addon.addon_id) > -1) addon.active = true;
            }
            // if id passed
            else if (type == "customer" && this.id) {
              // customer level
              let c = _.find(this.customers, { customer_id: this.id });

              if (c && c.addons.indexOf(addon.addon_id) > -1) addon.active = true;
            } else if (type == "unit" && this.id) {
              // unit level
              let u = _.find(this.units, { unit_id: this.id });

              if (u && u.addons.indexOf(addon.addon_id) > -1) addon.active = true;
            }

            // if found active on any of above cases push to activeAddons
            if (addon.active) activeAddons.push(addon);
            else inactiveAddons.push(addon);
          });

          this.addons = [...activeAddons, ...inactiveAddons];
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    async getAddonsList() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/addons/all/list`)
        .then((response) => {
          this.addons = response.data.addons;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getContactMailLink(addon_name) {
      return `mailto:${this.seller_email}?subject=Intresserad%20av%20tilläggstjänst%20${encodeURIComponent(addon_name)}&body=Hej,%20%0D%0AJag%20är%20intresserad%20av%20att%20veta%20mer%20om%20${encodeURIComponent(addon_name)},%20vänligen%20kontakta%20mig.`;
    },

    async getSeller() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/email/seller`);
        this.seller_email = response.data.email;
      } catch (error) {
        this.handleError(error);
      }
    },

    show(addon, addon_id) {
      console.log("🚀 ~ show ~ addon:", addon);

      if (this.user.role == "superuser") {
        if (addon.active && addon_id == "gps_geofence") this.$router.push(`/gps-kund/-`);
        else if (addon.active && addon_id == "rounding_surveillance") this.$router.push(`/ronderingsbevakning/customer/-`);
        else if (addon.active && addon_id == "alarm_reminder") this.$router.push(`/alarm-reminder/customer/-`);
        else if (addon.active && addon_id == "SAM") this.$router.push(`/policy-routine`);
        else if (addon.active && addon_id == "trip_report") this.$router.push(`/trip-report/customer/-`);
        else this.$modal.show(addon_id);
      }
      if (this.user.role == "user") {
        if (addon.active && addon_id == "gps_geofence") this.$router.push(`/gps-kund/${this.user.customers_id.length > 0 ? this.user.customers_id[0] : "-"}`);
        else if (addon.active && addon_id == "rounding_surveillance") this.$router.push(`/ronderingsbevakning/customer/${this.user.customers_id.length > 0 ? this.user.customers_id[0] : "-"}`);
        else if (addon.active && addon_id == "alarm_reminder") this.$router.push(`/alarm-reminder/customer/${this.user.customers_id.length > 0 ? this.user.customers_id[0] : "-"}`);
        else if (addon.active && addon_id == "SAM") this.$router.push(`/policy-routine`);
        else if (addon.active && addon_id == "trip_report") this.$router.push(`/trip-report/customer/-`);
        else this.$modal.show(addon_id);
      } else if ((this.user.role == "admin" || this.user.role == "seller" || this.user.role == "reseller") && this.id) {
        if (addon.active && addon_id == "gps_geofence") this.$router.push(`/gps-kund/${this.id}`);
        else if (addon.active && addon_id == "rounding_surveillance") this.$router.push(`/ronderingsbevakning/customer/${this.id}`);
        else if (addon.active && addon_id == "alarm_reminder") this.$router.push(`/alarm-reminder/customer/${this.id}`);
        else if (addon.active && addon_id == "SAM") this.$router.push(`/policy-routine`);
        else this.$modal.show(addon_id);
      } else this.$modal.show(addon_id);
    },

    hide(addon_id) {
      this.$modal.hide(addon_id);
    },
  },

  created() {
    if (this.user.role != "tech") this.getSeller();
    this.getAddons();
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
    this.setPageTitle(this.$t("top_titles.additional_services"), "addonBuy");
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.additional_services");
      this.setPageTitle(this.$t("top_titles.additional_services"), "addonBuy");
    },
  },
};
</script>
